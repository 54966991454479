// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-boarding-kennel-agreement-js": () => import("./../../../src/pages/boarding-kennel-agreement.js" /* webpackChunkName: "component---src-pages-boarding-kennel-agreement-js" */),
  "component---src-pages-cat-boarding-js": () => import("./../../../src/pages/cat-boarding.js" /* webpackChunkName: "component---src-pages-cat-boarding-js" */),
  "component---src-pages-dog-boarding-js": () => import("./../../../src/pages/dog-boarding.js" /* webpackChunkName: "component---src-pages-dog-boarding-js" */),
  "component---src-pages-dog-grooming-js": () => import("./../../../src/pages/dog-grooming.js" /* webpackChunkName: "component---src-pages-dog-grooming-js" */),
  "component---src-pages-dog-walking-js": () => import("./../../../src/pages/dog-walking.js" /* webpackChunkName: "component---src-pages-dog-walking-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-grooming-agreement-js": () => import("./../../../src/pages/grooming-agreement.js" /* webpackChunkName: "component---src-pages-grooming-agreement-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-request-reservation-js": () => import("./../../../src/pages/request-reservation.js" /* webpackChunkName: "component---src-pages-request-reservation-js" */),
  "component---src-pages-vaccination-policy-js": () => import("./../../../src/pages/vaccination-policy.js" /* webpackChunkName: "component---src-pages-vaccination-policy-js" */)
}

